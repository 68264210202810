import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
function About() {
  return (
    <>
    <Navbar></Navbar>
     <div class="container-fluid header-bg1 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <h1 class="display-4 text-white mb-3 animated slideInDown">About Us</h1>
         
        </div>
    </div>
 


    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <p><span className="text-primary me-2">#</span>Welcome To Symphony Furniture</p>
                    <h1 className="display-5 mb-4">Symphony Furniture<span className="text-primary"> Crafting Elegance</span>  and Comfort for Your Home </h1>
                    <p className="mb-4">At Symphony Furniture, we believe in enriching living spaces with elegance and functionality. Our meticulously crafted furniture is designed to enhance comfort and style in every room of your home. From luxurious chairs to sturdy beds, versatile tables to ergonomic office furniture, each piece is crafted with care and precision to elevate your living experience. With Symphony Furniture, you can trust in superior quality and timeless design that harmonizes with your lifestyle.</p>
                  <br></br>
                  <p className="mb-4">Craftsmanship: Our skilled artisans meticulously handcraft each piece using premium materials, ensuring durability and longevity.
 Comfort: Experience unparalleled comfort with our thoughtfully designed furniture tailored for relaxation and enjoyment.
 Style: From classic designs to modern aesthetics, our furniture reflects a blend of sophistication and innovation.
    Functionality: Whether it's optimizing space with multi-functional pieces or providing ergonomic solutions for your workspace, our furniture is designed with your needs in mind.
                    </p>
                    <a className="btn btn-primary py-3 px-5 mt-3" href="">Read More</a>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="img-border">
                        <img className="img-fluid" src="../styl/img/a1.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
 
<>






</>



  <Footer></Footer>
   
        
 
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default About
