const DATA = [
  
    {
        id: 1,
        title: "OCHAIR",
        name: "C1",
        price: 4072,
        desc: "Computer Chair C1",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img: ".././styl/pimg/test.png",
        img1: ".././styl/pimg/test1.png",
        img2: ".././styl/pimg/test2.png",
        img3: ".././styl/pimg/test3.png"
    }
,    
    {
        id : 2,
        title : "OCHAIR",
        name: "HB 02",
        price : 7070,
        desc : "High back HB 02",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o1chair1.png",
        img1 : ".././styl/pimg/o1chair2.png",
        img2 : ".././styl/pimg/o1chair3.png",
        img3 : ".././styl/pimg/o1chair2.png"
    },
    {
        id : 3,
        title : "OCHAIR",
        name: "MBN O1",
        price : 6280,
        desc : "Medium Back MBN 01",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o2chair1.png",
        img1 : ".././styl/pimg/o2chair2.png",
        img2 : ".././styl/pimg/o2chair3.png",
        img3 : ".././styl/pimg/o2chair2.png"
    },
    {
        id : 4,
        title : "OCHAIR",
        name: "NH01",
        price : 5930,
        desc : "Netted high back NH01",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o3chair1.png",
        img1 : ".././styl/pimg/o3chair2.png",
        img2 : ".././styl/pimg/o3chair3.png",
        img3 : ".././styl/pimg/o3chair2.png"
    },
    {
        id : 5,
        title : "OCHAIR",
        name: "MB 02",
        price : 4570,
        desc : "MB 02",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o4chair1.png",
        img1 : ".././styl/pimg/o4chair2.png",
        img2 : ".././styl/pimg/o4chair3.png",
        img3 : ".././styl/pimg/o4chair2.png"
    }, {
        id : 6,
        title : "OCHAIR",
        name: "VC 02",
        price : 4000,
        desc : "VC 02",
        specification: " Metal Base - Powder Coated, Seat - Foam, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o5chair1.png",
        img1 : ".././styl/pimg/o5chair2.png",
        img2 : ".././styl/pimg/o5chair3.png",
        img3 : ".././styl/pimg/o5chair2.png"
    },
    {
        id : 7,
        title : "OCHAIR",
        name: "MB 03",
        price : 1500,
        desc : "MB 03",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o6chair1.png",
        img1 : ".././styl/pimg/o6chair2.png",
        img2 : ".././styl/pimg/o6chair3.png",
        img3 : ".././styl/pimg/o6chair2.png"
    }, {
        id : 8,
        title : "OCHAIR",
        name: "CH PL1",
        price : 16700,
        desc : "CH PL1",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img : ".././styl/pimg/o7chair1.png",
        img1 : ".././styl/pimg/o7chair2.png",
        img2 : ".././styl/pimg/o7chair3.png",
        img3 : ".././styl/pimg/o7chair2.png"
    },
    {
        id : 9,
        title : "OCHAIR",
        name: "RC 06",
        price : 15860,
        desc : "RC 06",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img :  ".././styl/pimg/o8chair1.png",
        img1 : ".././styl/pimg/o8chair2.png",
        img2 : ".././styl/pimg/o8chair3.png",
        img3 : ".././styl/pimg/o8chair2.png"
    },
    {
        id : 10,
        title : "OCHAIR",
        name: "RC 04",
        price : 11740,
        desc : "RC 04",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img :  ".././styl/pimg/o9chair1.png",
        img1 : ".././styl/pimg/o9chair2.png",
        img2 : ".././styl/pimg/o9chair3.png",
        img3 : ".././styl/pimg/o9chair2.png"
    },
    {
        id : 11,
        title : "OCHAIR",
        name: "RC 03 A",
        price : 13200,
        desc : "RC 03 A",
        specification: "Hydraulic - Class 4, Metal Base - Powder Coated, Seat - Foam, Wheel - High Quality, Chair Height - Adjustable, Backrest, Armrest",
        warranty: "3 Year Warranty on Base and Mechanism",
        img :  ".././styl/pimg/o10chair1.png",
        img1 : ".././styl/pimg/o10chair2.png",
        img2 : ".././styl/pimg/o10chair3.png",
        img3 : ".././styl/pimg/o10chair2.png"
    }
    ,
    {
        id : 12,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch11.png",
        img1 : ".././styl/pimg/ch11.png",
        img2 : ".././styl/pimg/ch11.png",
        img3 : ".././styl/pimg/ch11.png"
    }
    ,
    {
        id : 13,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch12.png",
        img1 : ".././styl/pimg/ch12.png",
        img2 : ".././styl/pimg/ch12.png",
        img3 : ".././styl/pimg/ch12.png"
    }
    ,
    {
        id : 14,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch13.png",
        img1 : ".././styl/pimg/ch13.png",
        img2 : ".././styl/pimg/ch13.png",
        img3 : ".././styl/pimg/ch13.png"
    }
    ,
    {
        id : 15,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch14.png", 
        img1 : ".././styl/pimg/ch14.png",
        img2 : ".././styl/pimg/ch14.png",
        img3 : ".././styl/pimg/ch14.png"
    }
    ,
    {
        id : 16,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch15.png",
        img1 : ".././styl/pimg/ch15.png",
        img2 : ".././styl/pimg/ch15.png",
        img3 : ".././styl/pimg/ch15.png"
    }
    ,
    {
        id : 17,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch16.png",
        img1 : ".././styl/pimg/ch16.png",
        img2 : ".././styl/pimg/ch16.png",
        img3 : ".././styl/pimg/ch16.png"
    }
    ,
    {
        id : 18,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch17.png",
        img1 : ".././styl/pimg/ch17.png",
        img2 : ".././styl/pimg/ch17.png",
        img3 : ".././styl/pimg/ch17.png"
    }
    ,
    {
        id : 19,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch18.png",
        img1 : ".././styl/pimg/ch18.png",
        img2 : ".././styl/pimg/ch18.png",
        img3 : ".././styl/pimg/ch18.png"
    }
    ,
    // {
    //     id : 20,
    //     title : "CHAIR",
    //     name : " ",
    //     price : 0,
    //     desc : "",
    //     specification: "Full Stainless Steel ",
    //     warranty: "1 Year Warranty ",
       
    //     img : ".././styl/pimg/ch19.png",
    //      img1 : ".././styl/pimg/ch19.png",
    //     img2 : ".././styl/pimg/ch19.png",
    //     img3 : ".././styl/pimg/ch19.png"
    // }
    
   
    ,
    {
        id : 22,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch21.png",
         img1 : ".././styl/pimg/ch21.png",
        img2 : ".././styl/pimg/ch21.png",
        img3 : ".././styl/pimg/ch21.png"
    }
    ,
    {
        id : 23,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch22.png",
        img1 : ".././styl/pimg/ch22.png",
        img2 : ".././styl/pimg/ch22.png",
        img3 : ".././styl/pimg/ch22.png"
    }
    ,
    {
        id : 24,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch23.png",
        img1 : ".././styl/pimg/ch23.png",
        img2 : ".././styl/pimg/ch23.png",
        img3 : ".././styl/pimg/ch23.png"
    }
    ,
    {
        id : 25,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch24.png",
        img1 : ".././styl/pimg/ch24.png",
        img2 : ".././styl/pimg/ch24.png",
        img3 : ".././styl/pimg/ch24.png"
    }
    ,
    {
        id : 26,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch25.png",
        img1 : ".././styl/pimg/ch25.png",
        img2 : ".././styl/pimg/ch25.png",
        img3 : ".././styl/pimg/ch25.png"
    }
    ,
    {
        id : 27,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch26.png",
        img1 : ".././styl/pimg/ch26.png",
        img2 : ".././styl/pimg/ch26.png",
        img3 : ".././styl/pimg/ch26.png"
    }
    ,
    {
        id : 28,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/ch27.png",
        img1 : ".././styl/pimg/ch27.png",
        img2 : ".././styl/pimg/ch27.png",
        img3 : ".././styl/pimg/ch27.png"
    }
    ,
    {
        id : 29,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/chi7.jpg",
        img1 : ".././styl/pimg/chi7.jpg",
        img2 : ".././styl/pimg/chi7.jpg",
        img3 : ".././styl/pimg/chi7.jpg"
    }
    ,
    // {
    //     id : 30,
    //     title : "CHAIR",
    //     name : " ",
    //     price : 0,
    //     desc : "",
    //     specification: "Full Stainless Steel ",
    //     warranty: "1 Year Warranty ",
    //     img :  ".././styl/pimg/chi6.jpg",
    //     img1 : ".././styl/pimg/chi6.jpg",
    //     img2 : ".././styl/pimg/chi6.jpg",
    //     img3 : ".././styl/pimg/chi6.jpg"
    // }
    ,
    {
        id : 31,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/chi5.jpg",
        img1 : ".././styl/pimg/chi5.jpg",
        img2 : ".././styl/pimg/chi5.jpg",
        img3 : ".././styl/pimg/chi5.jpg"
    }
    ,
    {
        id : 32,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/chi4.jpg",
        img1 : ".././styl/pimg/chi4.jpg",
        img2 : ".././styl/pimg/chi4.jpg",
        img3 : ".././styl/pimg/chi4.jpg"
    }
    ,
    {
        id : 33,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/chi3.jpg",
        img1 : ".././styl/pimg/chi3.jpg",
        img2 : ".././styl/pimg/chi3.jpg",
        img3 : ".././styl/pimg/chi3.jpg"
    }
    ,
    {
        id : 34,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/chi2.jpg",
        img1 : ".././styl/pimg/chi2.jpg",
        img2 : ".././styl/pimg/chi2.jpg",
        img3 : ".././styl/pimg/chi2.jpg"
    }
    ,
    {
        id : 35,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/chi1.jpg",
        img1 : ".././styl/pimg/chi1.jpg",
        img2 : ".././styl/pimg/chi1.jpg",
        img3 : ".././styl/pimg/chi1.jpg"
    }
    ,
    {
        id : 36,
        title : "STAND",
        name : "S 78",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Foldable ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/std1.jpg",
        img1 : ".././styl/pimg/std2.jpg",
        img2 : ".././styl/pimg/std3.jpg",
        img3 : ".././styl/pimg/std4.jpg"
    }, 
  
    
    
    
    
    
    
    
    
    {
        id : 37,
        title : "Stand",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/sn1.png"
    },  {
        id : 38,
        title : "Stand",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/sn2.png"
    }, 
     {
        id : 39,
        title : "Bed",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/bed1.png"
    },  {
        id : 40,
        title : "Bed",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/bed2.png"
    },
    {
        id : 41,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/table1.png"
    }, {
        id : 42,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb1.png"
    }, {
        id : 43,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb2.png"
    }, {
        id : 44,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb3.png"
    }, {
        id : 45,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb4.png"
    }, {
        id : 46,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb5.png"
    }, {
        id : 47,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb6.png"
    }, {
        id : 48,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb7.png"
    }, {
        id : 49,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb8.png"
    }, {
        id : 50,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb9.png"
    }, {
        id : 51,
        title : "Table",
        price : 1500,
        desc : "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
        img : ".././styl/pimg/tb10.png"
    }, 
    {
        id : 52,
        title : "Manora",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img : ".././styl/pimg/m1.png",
        img1 : ".././styl/pimg/m1.png",
        img2 : ".././styl/pimg/m1.png",
        img3 : ".././styl/pimg/m1.png"
    },

    {
        id : 53,
        title : "BEDSHEET",
        name : "A 1",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet1.jpg",
        img1 : ".././styl/pimg/sheet1.jpg",
        img2 : ".././styl/pimg/sheet1.jpg",
        img3 : ".././styl/pimg/sheet1.jpg"
    }, 
  
    {
        id : 54,
        title : "BEDSHEET",
        name : "A 2",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet2.jpg",
        img1 : ".././styl/pimg/sheet2.jpg",
        img2 : ".././styl/pimg/sheet2.jpg",
        img3 : ".././styl/pimg/sheet2.jpg"
    }, 

    {
        id : 55,
        title : "BEDSHEET",
        name : "A 3",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet3.jpg",
        img1 : ".././styl/pimg/sheet3.jpg",
        img2 : ".././styl/pimg/sheet3.jpg",
        img3 : ".././styl/pimg/sheet3.jpg"
    }, 
    {
        id : 56,
        title : "BEDSHEET",
        name : "A 4",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet4.jpg",
        img1 : ".././styl/pimg/sheet4.jpg",
        img2 : ".././styl/pimg/sheet4.jpg",
        img3 : ".././styl/pimg/sheet4.jpg"
    }, 
    {
        id : 57,
        title : "BEDSHEET",
        name : "A 5",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet5.jpg",
        img1 : ".././styl/pimg/sheet5.jpg",
        img2 : ".././styl/pimg/sheet5.jpg",
        img3 : ".././styl/pimg/sheet5.jpg"
    }, 
    {
        id : 58,
        title : "BEDSHEET",
        name : "A 6",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet6.jpg",
        img1 : ".././styl/pimg/sheet6.jpg",
        img2 : ".././styl/pimg/sheet6.jpg",
        img3 : ".././styl/pimg/sheet6.jpg"
    }, 
    {
        id : 59,
        title : "BEDSHEET",
        name : "A 7",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet7.jpg",
        img1 : ".././styl/pimg/sheet7.jpg",
        img2 : ".././styl/pimg/sheet7.jpg",
        img3 : ".././styl/pimg/sheet7.jpg"
    }, 
    {
        id : 60,
        title : "BEDSHEET",
        name : "A 8",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet8.jpg",
        img1 : ".././styl/pimg/sheet8.jpg",
        img2 : ".././styl/pimg/sheet8.jpg",
        img3 : ".././styl/pimg/sheet8.jpg"
    }, 
    {
        id : 60,
        title : "BEDSHEET",
        name : "A 9",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet9.jpg",
        img1 : ".././styl/pimg/sheet9.jpg",
        img2 : ".././styl/pimg/sheet9.jpg",
        img3 : ".././styl/pimg/sheet9.jpg"
    }, 
    {
        id : 61,
        title : "BEDSHEET",
        name : "A 10",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet10.jpg",
        img1 : ".././styl/pimg/sheet10.jpg",
        img2 : ".././styl/pimg/sheet10.jpg",
        img3 : ".././styl/pimg/sheet10.jpg"
    }, 
    {
        id : 62,
        title : "BEDSHEET",
        name : "A 11",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet11.jpg",
        img1 : ".././styl/pimg/sheet11.jpg",
        img2 : ".././styl/pimg/sheet11.jpg",
        img3 : ".././styl/pimg/sheet11.jpg"
    }, 
    {
        id : 63,
        title : "BEDSHEET",
        name : "A 12",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet12.jpg",
        img1 : ".././styl/pimg/sheet12.jpg",
        img2 : ".././styl/pimg/sheet12.jpg",
        img3 : ".././styl/pimg/sheet12.jpg"
    }, 
    {
        id : 64,
        title : "BEDSHEET",
        name : "A 13",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet13.jpg",
        img1 : ".././styl/pimg/sheet13.jpg",
        img2 : ".././styl/pimg/sheet13.jpg",
        img3 : ".././styl/pimg/sheet13.jpg"
    }, 
    {
        id : 65,
        title : "BEDSHEET",
        name : "A 14",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet14.jpg",
        img1 : ".././styl/pimg/sheet14.jpg",
        img2 : ".././styl/pimg/sheet14.jpg",
        img3 : ".././styl/pimg/sheet14.jpg"
    }, 
    {
        id : 66,
        title : "BEDSHEET",
        name : "A 15",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet15.jpg",
        img1 : ".././styl/pimg/sheet15.jpg",
        img2 : ".././styl/pimg/sheet15.jpg",
        img3 : ".././styl/pimg/sheet15.jpg"
    }, 
    {
        id : 67,
        title : "BEDSHEET",
        name : "A 16",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet16.jpg",
        img1 : ".././styl/pimg/sheet16.jpg",
        img2 : ".././styl/pimg/sheet16.jpg",
        img3 : ".././styl/pimg/sheet16.jpg"
    }, 
    {
        id : 68,
        title : "BEDSHEET",
        name : "A 17",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet17.jpg",
        img1 : ".././styl/pimg/sheet17.jpg",
        img2 : ".././styl/pimg/sheet17.jpg",
        img3 : ".././styl/pimg/sheet17.jpg"
    }, 
    {
        id : 69,
        title : "BEDSHEET",
        name : "A 18",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet18.jpg",
        img1 : ".././styl/pimg/sheet18.jpg",
        img2 : ".././styl/pimg/sheet18.jpg",
        img3 : ".././styl/pimg/sheet18.jpg"
    }, 
    {
        id : 70,
        title : "BEDSHEET",
        name : "A 19",
        price : 0,
        desc : "",
        specification: "Cotton Feel Bedsheet for Double Bed with Two King Size Pillow Covers Soft/Breathable ",
        warranty: " ",
        img :  ".././styl/pimg/sheet19.jpg",
        img1 : ".././styl/pimg/sheet19.jpg",
        img2 : ".././styl/pimg/sheet19.jpg",
        img3 : ".././styl/pimg/sheet19.jpg"
    }, 


    {
        id : 71,
        title : "PODIUM",
        name : "S 38/1",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Blue colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium11.jpg",
        img1 : ".././styl/pimg/podium12.jpg",
        img2 : ".././styl/pimg/podium11.jpg",
        img3 : ".././styl/pimg/podium12.jpg"
    }, 
    {
        id : 72,
        title : "PODIUM",
        name : "S 38/2",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Blue colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium21.jpg",
        img1 : ".././styl/pimg/podium22.jpg",
        img2 : ".././styl/pimg/podium21.jpg",
        img3 : ".././styl/pimg/podium22.jpg"
    }, 

    {
        id : 73,
        title : "PODIUM",
        name : "S 38/3",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Yallow colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium31.jpg",
        img1 : ".././styl/pimg/podium32.jpg",
        img2 : ".././styl/pimg/podium33.jpg",
        img3 : ".././styl/pimg/podium32.jpg"
    }, 
    {
        id : 74,
        title : "PODIUM",
        name : "S 38/4",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Black colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium41.jpg",
        img1 : ".././styl/pimg/podium42.jpg",
        img2 : ".././styl/pimg/podium41.jpg",
        img3 : ".././styl/pimg/podium42.jpg"
    }, 

    {
        id : 75,
        title : "PODIUM",
        name : "S 38/5",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Red colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium51.jpg",
        img1 : ".././styl/pimg/podium52.jpg",
        img2 : ".././styl/pimg/podium51.jpg",
        img3 : ".././styl/pimg/podium52.jpg"
    },
    {
        id : 76,
        title : "PODIUM",
        name : "S 38/6",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Silver colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium61.jpg",
        img1 : ".././styl/pimg/podium62.jpg",
        img2 : ".././styl/pimg/podium63.jpg",
        img3 : ".././styl/pimg/podium62.jpg"
    },
    {
        id : 77,
        title : "PODIUM",
        name : "S 38/6",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Silver colour",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/podium61.jpg",
        img1 : ".././styl/pimg/podium62.jpg",
        img2 : ".././styl/pimg/podium63.jpg",
        img3 : ".././styl/pimg/podium62.jpg"
    },
    {
        id : 78,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable1.jpg",
        img1 : ".././styl/pimg/stable2.jpg",
        img2 : ".././styl/pimg/stable3.jpg",
        img3 : ".././styl/pimg/stable2.jpg"
    },
    {
        id : 79,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable21.png",
        img1 : ".././styl/pimg/stable22.jpg",
        img2 : ".././styl/pimg/stable21.png",
        img3 : ".././styl/pimg/stable22.jpg"
    },
    {
        id : 80,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable31.jpg",
        img1 : ".././styl/pimg/stable32.jpg",
        img2 : ".././styl/pimg/stable31.jpg",
        img3 : ".././styl/pimg/stable32.jpg"
    },
    {
        id : 81,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable41.jpg",
        img1 : ".././styl/pimg/stable42.jpg",
        img2 : ".././styl/pimg/stable41.jpg",
        img3 : ".././styl/pimg/stable42.jpg"
    },
    {
        id : 82,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable51.jpg",
        img1 : ".././styl/pimg/stable52.png",
        img2 : ".././styl/pimg/stable51.jpg",
        img3 : ".././styl/pimg/stable52.png"
    },
    {
        id : 83,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable61.png",
        img1 : ".././styl/pimg/stable62.png",
        img2 : ".././styl/pimg/stable61.png",
        img3 : ".././styl/pimg/stable62.png"
    },
    {
        id : 84,
        title : "STABLE",
        name : "",
        price : 0,
        desc : "",
        specification: "Frame mild steel, Top particle board",
        warranty: "",
        img :  ".././styl/pimg/stable71.png",
        img1 : ".././styl/pimg/stable72.png",
        img2 : ".././styl/pimg/stable71.png",
        img3 : ".././styl/pimg/stable72.png"
    },
    {
        id : 85,
        title : "CSTAND",
        name : "S 21",
        price : 0,
        desc : "S.S Saree Stand",
        specification: "Full Stainless Steel, Adjustable",
        warranty: "",
        img :  ".././styl/pimg/a.jpg",
        img1 : ".././styl/pimg/a1.jpg",
        img2 : ".././styl/pimg/a3.jpg",
        img3 : ".././styl/pimg/a1.jpg"
    },
    {
        id : 86,
        title : "CSTAND",
        name : "S 26",
        price : 0,
        desc : "S.S Coat Stand",
        specification: "Full Stainless Steel, Adjustable",
        warranty: "",
        img :  ".././styl/pimg/f.jpg",
        img1 : ".././styl/pimg/f.jpg",
        img2 : ".././styl/pimg/f.jpg",
        img3 : ".././styl/pimg/f.jpg"
    },
    {
        id : 87,
        title : "CSTAND",
        name : "S 26/1",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Adjustable",
        warranty: "",
        img :  ".././styl/pimg/b.jpg",
        img1 : ".././styl/pimg/b1.jpg",
        img2 : ".././styl/pimg/b2.jpg",
        img3 : ".././styl/pimg/b3.jpg"
    },
    {
        id : 88,
        title : "CSTAND",
        name : "S 26/2",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Adjustable",
        warranty: "",
        img :  ".././styl/pimg/c.jpg",
        img1 : ".././styl/pimg/c1.jpg",
        img2 : ".././styl/pimg/c.jpg",
        img3 : ".././styl/pimg/c1.jpg"
    },
    {
        id : 89,
        title : "CSTAND",
        name : "S 26/3",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel, Adjustable",
        warranty: "",
        img :  ".././styl/pimg/d.jpg",
        img1 : ".././styl/pimg/d1.jpg",
        img2 : ".././styl/pimg/d.jpg",
        img3 : ".././styl/pimg/d1.jpg"
    },
    {
        id : 90,
        title : "MATTRESS",
        name : "T 20",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t1.jpg",
        img1 : ".././styl/pimg/t1.jpg",
        img2 : ".././styl/pimg/t1.jpg",
        img3 : ".././styl/pimg/t1.jpg"
    },
    {
        id : 91,
        title : "MATTRESS",
        name : "T 21",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t2.jpg",
        img1 : ".././styl/pimg/t2.jpg",
        img2 : ".././styl/pimg/t2.jpg",
        img3 : ".././styl/pimg/t2.jpg"
    },
    {
        id : 92,
        title : "MATTRESS",
        name : "T 23",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t3.jpg",
        img1 : ".././styl/pimg/t3.jpg",
        img2 : ".././styl/pimg/t3.jpg",
        img3 : ".././styl/pimg/t3.jpg"
    },
    {
        id : 93,
        title : "MATTRESS",
        name : "T 24",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t4.jpg",
        img1 : ".././styl/pimg/t4.jpg",
        img2 : ".././styl/pimg/t4.jpg",
        img3 : ".././styl/pimg/t4.jpg"
    },
    {
        id : 94,
        title : "MATTRESS",
        name : "T 25",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t5.jpg",
        img1 : ".././styl/pimg/t5.jpg",
        img2 : ".././styl/pimg/t5.jpg",
        img3 : ".././styl/pimg/t5.jpg"
    },
    {
        id : 95,
        title : "MATTRESS",
        name : "T 26",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t6.jpg",
        img1 : ".././styl/pimg/t6.jpg",
        img2 : ".././styl/pimg/t6.jpg",
        img3 : ".././styl/pimg/t6.jpg"
    },
    {
        id : 96,
        title : "MATTRESS",
        name : "T 27",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t7.jpg",
        img1 : ".././styl/pimg/t7.jpg",
        img2 : ".././styl/pimg/t7.jpg",
        img3 : ".././styl/pimg/t7.jpg"
    },
    {
        id : 97,
        title : "FBEDSHEET",
        name : "A 20",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs1.jpg",
        img1 : ".././styl/pimg/fs1.jpg",
        img2 : ".././styl/pimg/fs1.jpg",
        img3 : ".././styl/pimg/fs1.jpg"
    },
    {
        id : 98,
        title : "FBEDSHEET",
        name : "A 21",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs2.jpg",
        img1 : ".././styl/pimg/fs2.jpg",
        img2 : ".././styl/pimg/fs2.jpg",
        img3 : ".././styl/pimg/fs2.jpg"
    },
    {
        id : 99,
        title : "FBEDSHEET",
        name : "A 22",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs3.jpg",
        img1 : ".././styl/pimg/fs3.jpg",
        img2 : ".././styl/pimg/fs3.jpg",
        img3 : ".././styl/pimg/fs3.jpg"
    },
    {
        id : 100,
        title : "FBEDSHEET",
        name : "A 23",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs4.jpg",
        img1 : ".././styl/pimg/fs4.jpg",
        img2 : ".././styl/pimg/fs4.jpg",
        img3 : ".././styl/pimg/fs4.jpg"
    },
    {
        id : 101,
        title : "FBEDSHEET",
        name : "A 24",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs5.jpg",
        img1 : ".././styl/pimg/fs5.jpg",
        img2 : ".././styl/pimg/fs5.jpg",
        img3 : ".././styl/pimg/fs5.jpg"
    },
    {
        id : 102,
        title : "FBEDSHEET",
        name : "A 25",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs6.jpg",
        img1 : ".././styl/pimg/fs6.jpg",
        img2 : ".././styl/pimg/fs6.jpg",
        img3 : ".././styl/pimg/fs6.jpg"
    },
    {
        id : 103,
        title : "FBEDSHEET",
        name : "A 26",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs7.jpg",
        img1 : ".././styl/pimg/fs7.jpg",
        img2 : ".././styl/pimg/fs7.jpg",
        img3 : ".././styl/pimg/fs7.jpg"
    },
    {
        id : 104,
        title : "FBEDSHEET",
        name : "A 27",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs8.jpg",
        img1 : ".././styl/pimg/fs8.jpg",
        img2 : ".././styl/pimg/fs8.jpg",
        img3 : ".././styl/pimg/fs8.jpg"
    },
    {
        id : 105,
        title : "FBEDSHEET",
        name : "A 28",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs9.jpg",
        img1 : ".././styl/pimg/fs9.jpg",
        img2 : ".././styl/pimg/fs9.jpg",
        img3 : ".././styl/pimg/fs9.jpg"
    },
    {
        id : 106,
        title : "FBEDSHEET",
        name : "A 29",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs10.jpg",
        img1 : ".././styl/pimg/fs10.jpg",
        img2 : ".././styl/pimg/fs10.jpg",
        img3 : ".././styl/pimg/fs10.jpg"
    },
    {
        id : 107,
        title : "FBEDSHEET",
        name : "A 30",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs11.jpg",
        img1 : ".././styl/pimg/fs11.jpg",
        img2 : ".././styl/pimg/fs11.jpg",
        img3 : ".././styl/pimg/fs11.jpg"
    },
    {
        id : 108,
        title : "FBEDSHEET",
        name : "A 31",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs12.jpg",
        img1 : ".././styl/pimg/fs12.jpg",
        img2 : ".././styl/pimg/fs12.jpg",
        img3 : ".././styl/pimg/fs12.jpg"
    },
    {
        id : 109,
        title : "FBEDSHEET",
        name : "A 32",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs13.jpg",
        img1 : ".././styl/pimg/fs13.jpg",
        img2 : ".././styl/pimg/fs13.jpg",
        img3 : ".././styl/pimg/fs13.jpg"
    },
    {
        id : 110,
        title : "FBEDSHEET",
        name : "A 33",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs14.jpg",
        img1 : ".././styl/pimg/fs14.jpg",
        img2 : ".././styl/pimg/fs14.jpg",
        img3 : ".././styl/pimg/fs14.jpg"
    },
    {
        id : 111,
        title : "FBEDSHEET",
        name : "A 34",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs15.jpg",
        img1 : ".././styl/pimg/fs15.jpg",
        img2 : ".././styl/pimg/fs15.jpg",
        img3 : ".././styl/pimg/fs15.jpg"
    },
    {
        id : 112,
        title : "FBEDSHEET",
        name : "A 35",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs16.jpg",
        img1 : ".././styl/pimg/fs16.jpg",
        img2 : ".././styl/pimg/fs16.jpg",
        img3 : ".././styl/pimg/fs16.jpg"
    },
    {
        id : 113,
        title : "FBEDSHEET",
        name : "A 36",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs17.jpg",
        img1 : ".././styl/pimg/fs17.jpg",
        img2 : ".././styl/pimg/fs17.jpg",
        img3 : ".././styl/pimg/fs17.jpg"
    },
    {
        id : 114,
        title : "FBEDSHEET",
        name : "A 37",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs18.jpg",
        img1 : ".././styl/pimg/fs18.jpg",
        img2 : ".././styl/pimg/fs18.jpg",
        img3 : ".././styl/pimg/fs18.jpg"
    },
    {
        id : 115,
        title : "FBEDSHEET",
        name : "A 38",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs19.jpg",
        img1 : ".././styl/pimg/fs19.jpg",
        img2 : ".././styl/pimg/fs19.jpg",
        img3 : ".././styl/pimg/fs19.jpg"
    },
    {
        id : 116,
        title : "FBEDSHEET",
        name : "A 39",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs20.jpg",
        img1 : ".././styl/pimg/fs20.jpg",
        img2 : ".././styl/pimg/fs20.jpg",
        img3 : ".././styl/pimg/fs20.jpg"
    },
    {
        id : 117,
        title : "FBEDSHEET",
        name : "A 40",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs21.jpg",
        img1 : ".././styl/pimg/fs21.jpg",
        img2 : ".././styl/pimg/fs21.jpg",
        img3 : ".././styl/pimg/fs21.jpg"
    },
    {
        id : 118,
        title : "FBEDSHEET",
        name : "A 41",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs22.jpg",
        img1 : ".././styl/pimg/fs22.jpg",
        img2 : ".././styl/pimg/fs22.jpg",
        img3 : ".././styl/pimg/fs22.jpg"
    },
    {
        id : 119,
        title : "FBEDSHEET",
        name : "A 42",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs23.jpg",
        img1 : ".././styl/pimg/fs23.jpg",
        img2 : ".././styl/pimg/fs23.jpg",
        img3 : ".././styl/pimg/fs23.jpg"
    },
    {
        id : 120,
        title : "FBEDSHEET",
        name : "A 43",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs24.jpg",
        img1 : ".././styl/pimg/fs24.jpg",
        img2 : ".././styl/pimg/fs24.jpg",
        img3 : ".././styl/pimg/fs24.jpg"
    },
    {
        id : 121,
        title : "FBEDSHEET",
        name : "A 44",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs25.jpg",
        img1 : ".././styl/pimg/fs25.jpg",
        img2 : ".././styl/pimg/fs25.jpg",
        img3 : ".././styl/pimg/fs25.jpg"
    },
    {
        id : 122,
        title : "FBEDSHEET",
        name : "A 45",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs26.jpg",
        img1 : ".././styl/pimg/fs26.jpg",
        img2 : ".././styl/pimg/fs26.jpg",
        img3 : ".././styl/pimg/fs26.jpg"
    },
    {
        id : 123,
        title : "FBEDSHEET",
        name : "A 46",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs27.jpg",
        img1 : ".././styl/pimg/fs27.jpg",
        img2 : ".././styl/pimg/fs27.jpg",
        img3 : ".././styl/pimg/fs27.jpg"
    },
    {
        id : 124,
        title : "FBEDSHEET",
        name : "A 47",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs28.jpg",
        img1 : ".././styl/pimg/fs28.jpg",
        img2 : ".././styl/pimg/fs28.jpg",
        img3 : ".././styl/pimg/fs28.jpg"
    },
    {
        id : 125,
        title : "FBEDSHEET",
        name : "A 48",
        price : 0,
        desc : "",
        specification: "familycot bedsheet, cotton, Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/fs29.jpg",
        img1 : ".././styl/pimg/fs29.jpg",
        img2 : ".././styl/pimg/fs29.jpg",
        img3 : ".././styl/pimg/fs29.jpg"
    },
    {
        id : 126,
        title : "PILLOW",
        name : "A  49",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl1.jpg",
        img1 : ".././styl/pimg/pl1.jpg",
        img2 : ".././styl/pimg/pl1.jpg",
        img3 : ".././styl/pimg/pl1.jpg"
    },
    {
        id : 127,
        title : "PILLOW",
        name : "A  50",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl2.jpg",
        img1 : ".././styl/pimg/pl2.jpg",
        img2 : ".././styl/pimg/pl2.jpg",
        img3 : ".././styl/pimg/pl2.jpg"
    },
    {
        id : 128,
        title : "PILLOW",
        name : "A  51",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl3.jpg",
        img1 : ".././styl/pimg/pl3.jpg",
        img2 : ".././styl/pimg/pl3.jpg",
        img3 : ".././styl/pimg/pl3.jpg"
    },
    {
        id : 129,
        title : "PILLOW",
        name : "A  52",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl4.jpg",
        img1 : ".././styl/pimg/pl4.jpg",
        img2 : ".././styl/pimg/pl4.jpg",
        img3 : ".././styl/pimg/pl4.jpg"
    },
    {
        id : 130,
        title : "PILLOW",
        name : "A  53",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl5.jpg",
        img1 : ".././styl/pimg/pl5.jpg",
        img2 : ".././styl/pimg/pl5.jpg",
        img3 : ".././styl/pimg/pl5.jpg"
    },
    {
        id : 131,
        title : "PILLOW",
        name : "A  54",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl6.jpg",
        img1 : ".././styl/pimg/pl6.jpg",
        img2 : ".././styl/pimg/pl6.jpg",
        img3 : ".././styl/pimg/pl6.jpg"
    },
    {
        id : 132,
        title : "PILLOW",
        name : "A  55",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl7.jpg",
        img1 : ".././styl/pimg/pl7.jpg",
        img2 : ".././styl/pimg/pl7.jpg",
        img3 : ".././styl/pimg/pl7.jpg"
    },
    {
        id : 133,
        title : "PILLOW",
        name : "A  56",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl8.jpg",
        img1 : ".././styl/pimg/pl8.jpg",
        img2 : ".././styl/pimg/pl8.jpg",
        img3 : ".././styl/pimg/pl8.jpg"
    },
    {
        id : 134,
        title : "PILLOW",
        name : "A  57",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl9.jpg",
        img1 : ".././styl/pimg/pl9.jpg",
        img2 : ".././styl/pimg/pl9.jpg",
        img3 : ".././styl/pimg/pl9.jpg"
    },
    {
        id : 135,
        title : "PILLOW",
        name : "A  58",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl10.jpg",
        img1 : ".././styl/pimg/pl10.jpg",
        img2 : ".././styl/pimg/pl10.jpg",
        img3 : ".././styl/pimg/pl10.jpg"
    },
    {
        id : 136,
        title : "PILLOW",
        name : "A  59",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl11.jpg",
        img1 : ".././styl/pimg/pl11.jpg",
        img2 : ".././styl/pimg/pl11.jpg",
        img3 : ".././styl/pimg/pl11.jpg"
    },
    {
        id : 137,
        title : "PILLOW",
        name : "A  60",
        price : 0,
        desc : "",
        specification: " cotton,Custom Sizes Available",
        warranty: " ",
        img :  ".././styl/pimg/pl12.jpg",
        img1 : ".././styl/pimg/pl13.jpg",
        img2 : ".././styl/pimg/pl14.jpg",
        img3 : ".././styl/pimg/pl14.jpg"
    },

    {
        id : 138,
        title : "BOXSHEET",
        name : "A  61",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx1.jpg",
        img1 : ".././styl/pimg/bx1.jpg",
        img2 : ".././styl/pimg/bx1.jpg",
        img3 : ".././styl/pimg/bx1.jpg"
    },
    {
        id : 139,
        title : "BOXSHEET",
        name : "A  62",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx2.jpg",
        img1 : ".././styl/pimg/bx2.jpg",
        img2 : ".././styl/pimg/bx2.jpg",
        img3 : ".././styl/pimg/bx2.jpg"
    },
    {
        id : 140,
        title : "BOXSHEET",
        name : "A  63",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx3.jpg",
        img1 : ".././styl/pimg/bx3.jpg",
        img2 : ".././styl/pimg/bx3.jpg",
        img3 : ".././styl/pimg/bx3.jpg"
    },
    {
        id : 141,
        title : "BOXSHEET",
        name : "A  64",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx4.jpg",
        img1 : ".././styl/pimg/bx4.jpg",
        img2 : ".././styl/pimg/bx4.jpg",
        img3 : ".././styl/pimg/bx4.jpg"
    },
    {
        id : 142,
        title : "BOXSHEET",
        name : "A  65",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx5.jpg",
        img1 : ".././styl/pimg/bx5.jpg",
        img2 : ".././styl/pimg/bx5.jpg",
        img3 : ".././styl/pimg/bx5.jpg"
    },
    {
        id : 143,
        title : "BOXSHEET",
        name : "A  66",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx6.jpg",
        img1 : ".././styl/pimg/bx6.jpg",
        img2 : ".././styl/pimg/bx6.jpg",
        img3 : ".././styl/pimg/bx6.jpg"
    },
    {
        id : 144,
        title : "BOXSHEET",
        name : "A  67",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx7.jpg",
        img1 : ".././styl/pimg/bx7.jpg",
        img2 : ".././styl/pimg/bx7.jpg",
        img3 : ".././styl/pimg/bx7.jpg"
    },
    {
        id : 145,
        title : "BOXSHEET",
        name : "A  68",
        price : 0,
        desc : "",
        specification: "Made from soft, durable materials, they offer comfort. Available in various sizes and colors, these sheets are perfect for any bedroom decor.",
        warranty: " ",
        img :  ".././styl/pimg/bx8.jpg",
        img1 : ".././styl/pimg/bx8.jpg",
        img2 : ".././styl/pimg/bx8.jpg",
        img3 : ".././styl/pimg/bx8.jpg"
    },
    {
        id : 146,
        title : "KBSHEET",
        name : "A  69",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb1.jpg",
        img1 : ".././styl/pimg/kb1.jpg",
        img2 : ".././styl/pimg/kb1.jpg",
        img3 : ".././styl/pimg/kb1.jpg"
    },
    {
        id : 147,
        title : "KBSHEET",
        name : "A  70",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb2.jpg",
        img1 : ".././styl/pimg/kb2.jpg",
        img2 : ".././styl/pimg/kb2.jpg",
        img3 : ".././styl/pimg/kb2.jpg"
    },
    {
        id : 148,
        title : "KBSHEET",
        name : "A  71",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb3.jpg",
        img1 : ".././styl/pimg/kb3.jpg",
        img2 : ".././styl/pimg/kb3.jpg",
        img3 : ".././styl/pimg/kb3.jpg"
    },
    {
        id : 149,
        title : "KBSHEET",
        name : "A  72",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb4.jpg",
        img1 : ".././styl/pimg/kb4.jpg",
        img2 : ".././styl/pimg/kb4.jpg",
        img3 : ".././styl/pimg/kb4.jpg"
    },
    {
        id : 150,
        title : "KBSHEET",
        name : "A  73",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb5.jpg",
        img1 : ".././styl/pimg/kb5.jpg",
        img2 : ".././styl/pimg/kb5.jpg",
        img3 : ".././styl/pimg/kb5.jpg"
    },
    {
        id : 151,
        title : "KBSHEET",
        name : "A  74",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb6.jpg",
        img1 : ".././styl/pimg/kb6.jpg",
        img2 : ".././styl/pimg/kb6.jpg",
        img3 : ".././styl/pimg/kb6.jpg"
    },
    {
        id : 152,
        title : "KBSHEET",
        name : "A  75",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb7.jpg",
        img1 : ".././styl/pimg/kb7.jpg",
        img2 : ".././styl/pimg/kb7.jpg",
        img3 : ".././styl/pimg/kb7.jpg"
    },
    {
        id : 153,
        title : "KBSHEET",
        name : "A  76",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb8.jpg",
        img1 : ".././styl/pimg/kb8.jpg",
        img2 : ".././styl/pimg/kb8.jpg",
        img3 : ".././styl/pimg/kb8.jpg"
    },
    {
        id : 154,
        title : "KBSHEET",
        name : "A  77",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb9.jpg",
        img1 : ".././styl/pimg/kb9.jpg",
        img2 : ".././styl/pimg/kb9.jpg",
        img3 : ".././styl/pimg/kb9.jpg"
    },
    {
        id : 155,
        title : "KBSHEET",
        name : "A  78",
        price : 0,
        desc : "",
        specification: "King Bed: For those who desire maximum space, our king beds ensure a luxurious sleep experience.",
        warranty: " ",
        img :  ".././styl/pimg/kb10.jpg",
        img1 : ".././styl/pimg/kb10.jpg",
        img2 : ".././styl/pimg/kb10.jpg",
        img3 : ".././styl/pimg/kb10.jpg"
    },
    {
        id : 156,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al11.png",
        img1 : ".././styl/pimg/al12.png",
        img2 : ".././styl/pimg/al11.png",
        img3 : ".././styl/pimg/al12.png"
    },

    {
        id : 157,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al21.png",
        img1 : ".././styl/pimg/al22.png",
        img2 : ".././styl/pimg/al21.png",
        img3 : ".././styl/pimg/al22.png"
    },
    {
        id : 158,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al31.png",
        img1 : ".././styl/pimg/al32.png",
        img2 : ".././styl/pimg/al31.png",
        img3 : ".././styl/pimg/al32.png"
    },
    {
        id : 159,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al41.png",
        img1 : ".././styl/pimg/al42.png",
        img2 : ".././styl/pimg/al41.png",
        img3 : ".././styl/pimg/al42.png"
    },
    {
        id : 160,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al51.png",
        img1 : ".././styl/pimg/al52.png",
        img2 : ".././styl/pimg/al51.png",
        img3 : ".././styl/pimg/al52.png"
    },

    {
        id : 161,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "Width 3 Feet",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al61.png",
        img1 : ".././styl/pimg/al62.png",
        img2 : ".././styl/pimg/al61.png",
        img3 : ".././styl/pimg/al62.png"
    },
    {
        id : 162,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al71.png",
        img1 : ".././styl/pimg/al72.png",
        img2 : ".././styl/pimg/al71.png",
        img3 : ".././styl/pimg/al72.png"
    },
    {
        id : 163,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al81.png",
        img1 : ".././styl/pimg/al82.png",
        img2 : ".././styl/pimg/al81.png",
        img3 : ".././styl/pimg/al82.png"
    },
    {
        id : 164,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al91.png",
        img1 : ".././styl/pimg/al92.png",
        img2 : ".././styl/pimg/al91.png",
        img3 : ".././styl/pimg/al92.png"
    },
    {
        id : 165,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al101.png",
        img1 : ".././styl/pimg/al102.png",
        img2 : ".././styl/pimg/al101.png",
        img3 : ".././styl/pimg/al102.png"
    },
    {
        id : 166,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al111.png",
        img1 : ".././styl/pimg/al112.png",
        img2 : ".././styl/pimg/al111.png",
        img3 : ".././styl/pimg/al112.png"
    },
    {
        id : 167,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al121.png",
        img1 : ".././styl/pimg/al122.png",
        img2 : ".././styl/pimg/al121.png",
        img3 : ".././styl/pimg/al122.png"
    },


    {
        id : 168,
        title : "CTABLE",
        name : "Computer Table",
        price : 0,
        desc : "Computer Table",
        specification: "Partical board material, computer table,2.5*1.5 , 3*1.5, 3*2, 4*2,size are available",
        warranty: " 1 Year Warranty",
        img :  ".././styl/pimg/cmtable1.jpg",
        img1 : ".././styl/pimg/cmtable1.jpg",
        img2 : ".././styl/pimg/cmtable1.jpg",
        img3 : ".././styl/pimg/cmtable1.jpg"
    },

    {
        id : 169,
        title : "BIGSTABLE",
        name : "Study Table",
        price : 3299,
        desc : "Study Table",
        specification: ",practical board material.different model Two colours Available",
        warranty: " 1 Year Warranty",
        img :  ".././styl/pimg/bt1.jpg",
        img1 : ".././styl/pimg/bt2.jpg",
        img2 : ".././styl/pimg/bt1.jpg",
        img3 : ".././styl/pimg/bt2.jpg"
    },

    {
        id : 170,
        title : "BEDSIDE",
        name : "Bed Side",
        price : 0,
        desc : "Bed Side",
        specification: "Bed side self small, partical board materia",
        warranty: " 1 Year Warranty",
        img :  ".././styl/pimg/beds.jpg",
        img1 : ".././styl/pimg/beds.jpg",
        img2 : ".././styl/pimg/beds.jpg",
        img3 : ".././styl/pimg/beds.jpg"
    },

    {
        id : 171,
        title : "BEDSIDE",
        name : "Bed Side",
        price : 0,
        desc : "Bed Side",
        specification: "Bed side self big,, partical board materia",
        warranty: " 1 Year Warranty",
        img :  ".././styl/pimg/beds1.jpg",
        img1 : ".././styl/pimg/beds1.jpg",
        img2 : ".././styl/pimg/beds1.jpg",
        img3 : ".././styl/pimg/beds1.jpg"
    },


    {
        id : 172,
        title : "MATTRESS",
        name : "T 27",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t10.jpg",
        img1 : ".././styl/pimg/t101.jpg",
        img2 : ".././styl/pimg/t10.jpg",
        img3 : ".././styl/pimg/t101.jpg"
    },

    {
        id : 173,
        title : "MATTRESS",
        name : "T 27",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t9.jpg",
        img1 : ".././styl/pimg/t91.jpg",
        img2 : ".././styl/pimg/t9.jpg",
        img3 : ".././styl/pimg/t91.jpg"
    },

    {
        id : 174 ,
        title : "MATTRESS",
        name : "T 27",
        price : 0,
        desc : "",
        specification: "Single Bed,Twin Bed,Double Bed,Queen Bed,King Bed,California King Bed,Super King Bed,Custom Sizes Available",
        warranty: "2 Year Warranty ",
        img :  ".././styl/pimg/t8.jpg",
        img1 : ".././styl/pimg/t8.jpg",
        img2 : ".././styl/pimg/t8.jpg",
        img3 : ".././styl/pimg/t8.jpg"
    },

    {
        id : 175,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al131.png",
        img1 : ".././styl/pimg/al131.png",
        img2 : ".././styl/pimg/al131.png",
        img3 : ".././styl/pimg/al131.png"
    },

    {
        id : 176,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al311.jpg",
        img1 : ".././styl/pimg/al333.jpg",
        img2 : ".././styl/pimg/al334.jpg",
        img3 : ".././styl/pimg/al333.jpg"
    },
    {
        id : 177,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "Length 4'5 , width 3'5",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al444.jpg",
        img1 : ".././styl/pimg/al444.jpg",
        img2 : ".././styl/pimg/al444.jpg",
        img3 : ".././styl/pimg/al444.jpg"
    },
    {
        id : 178,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "Length 6'5 , width 3'5",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al55.jpg",
        img1 : ".././styl/pimg/al55.jpg",
        img2 : ".././styl/pimg/al55.jpg",
        img3 : ".././styl/pimg/al55.jpg"
    },
    {
        id : 179,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "Length 6 , width 2",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al666.jpg",
        img1 : ".././styl/pimg/al99.jpg",
        img2 : ".././styl/pimg/al666.jpg",
        img3 : ".././styl/pimg/al99.jpg"
    },
    {
        id : 180,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al0.jpg",
        img1 : ".././styl/pimg/al01.jpg",
        img2 : ".././styl/pimg/al02.jpg",
        img3 : ".././styl/pimg/al03.jpg"
    },
    {
        id : 181,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al044.jpg",
        img1 : ".././styl/pimg/al04.jpg",
        img2 : ".././styl/pimg/al044.jpg",
        img3 : ".././styl/pimg/al04.jpg"
    },
    {
        id : 182,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al0012.jpg",
        img1 : ".././styl/pimg/al0013.jpg",
        img2 : ".././styl/pimg/al0012.jpg",
        img3 : ".././styl/pimg/al0013.jpg"
    },
    {
        id : 183,
        title : "WARDROBE",
        name : "",
        price : 0,
        desc : "",
        specification: "",
        warranty: " ",
        img :  ".././styl/pimg/al91.jpg",
        img1 : ".././styl/pimg/al92.jpg",
        img2 : ".././styl/pimg/al91.jpg",
        img3 : ".././styl/pimg/al92.jpg"
    },
    {
        id : 184,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/ch01.jpg",
        img1 : ".././styl/pimg/ch01.jpg",
        img2 : ".././styl/pimg/ch01.jpg",
        img3 : ".././styl/pimg/ch01.jpg"
    }
    ,
    {
        id : 185,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/ch0492.jpg",
        img1 : ".././styl/pimg/ch0492.jpg",
        img2 : ".././styl/pimg/ch0492.jpg",
        img3 : ".././styl/pimg/ch0492.jpg"
    }
    ,
    {
        id : 186,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/ch0391.jpg",
        img1 : ".././styl/pimg/ch0391.jpg",
        img2 : ".././styl/pimg/ch0391.jpg",
        img3 : ".././styl/pimg/ch0391.jpg"
    }
    ,
    {
        id : 187,
        title : "CHAIR",
        name : " ",
        price : 0,
        desc : "",
        specification: "Full Stainless Steel ",
        warranty: "1 Year Warranty ",
        img :  ".././styl/pimg/ch0290.jpg",
        img1 : ".././styl/pimg/ch0290.jpg",
        img2 : ".././styl/pimg/ch0290.jpg",
        img3 : ".././styl/pimg/ch0290.jpg"
    }
    ,
]

export default DATA;