import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router,Link,NavLink,Route,Routes} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Allproduct from './Pages/Allproduct';
import ProductDetail from './Pages/ProductDetail';
import Chair from './Pages/Chair';
import Stand from './Pages/Stand';
import Bed from './Pages/Bed';
import Table from './Pages/Table';
import Dtable from './Pages/Dtable';
import Cradle from './Pages/Cradle';
import Manora from './Pages/Manora';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import Coomingsoon from './Pages/Coomingsoon';
import Officechair from './Pages/Officechair';
import Bedsheet from './Pages/Bedsheet';
import Podium from './Pages/Podium';
import StudyTable from './Pages/StudyTable';
import ClothStand from './Pages/ClothStand';
import Mattress from './Pages/Mattress';
import FamilycotBedsheet from './Pages/FamilycotBedsheet';
import PillowJodi from './Pages/PillowJodi';
import BoxtypeBedsheet from './Pages/BoxtypeBedsheet';
import KingBedsheet from './Pages/KingBedsheet';
import Wardrobe from './Pages/Wardrobe';
import ComputerTable from './Pages/ComputerTable';
import BigStudytable from './Pages/BigStudytable';
import Bedside from './Pages/Bedside';
function App() {
  
  return (
   <>
   
   <Router>
      <>
    
   
  

  
  
      <Routes><Route exact path={"/"} element={<Home></Home>}></Route>  
      <Route path={"/About"} element={<About></About>}></Route>
      <Route path={"/Allproduct"} element={<Allproduct></Allproduct>}></Route>
      <Route path={"/Chair"} element={<Chair></Chair>}></Route>
      <Route path={"/Stand"} element={<Stand></Stand>}></Route>
      <Route path={"/Bed"} element={<Bed></Bed>}></Route>
      <Route path={"/Table"} element={<Table></Table>}></Route>
      <Route path={"/Cradle"} element={<Cradle></Cradle>}></Route>
      <Route path={"/Dtable"} element={<Dtable></Dtable>}></Route>
      <Route path={"/Services"} element={<Services></Services>}></Route>
      <Route path={"/Manora"} element={<Manora></Manora>}></Route>
      <Route path={"/Contact"} element={<Contact></Contact>}></Route>
      <Route path={"/products/:id"} element={<ProductDetail></ProductDetail>}></Route>
      <Route path={"/Coomingsoon"} element={<Coomingsoon></Coomingsoon>}></Route>
      <Route path={"/Officechair"} element={<Officechair></Officechair>}></Route>
      <Route path={"/Bedsheet"} element={<Bedsheet></Bedsheet>}></Route>
      <Route path={"/Podium"} element={<Podium></Podium>}></Route>ClothStand
      <Route path={"/StudyTable"} element={<StudyTable></StudyTable>}></Route>
      <Route path={"/ClothStand"} element={<ClothStand></ClothStand>}></Route>
      <Route path={"/Mattress"} element={<Mattress></Mattress>}></Route>
      <Route path={"/FamilycotBedsheet"} element={<FamilycotBedsheet></FamilycotBedsheet>}></Route>
      <Route path={"/PillowJodi"} element={<PillowJodi></PillowJodi>}></Route>
      <Route path={"/BoxtypeBedsheet"} element={<BoxtypeBedsheet></BoxtypeBedsheet>}></Route>
      <Route path={"/KingBedsheet"} element={<KingBedsheet></KingBedsheet>}></Route>
      <Route path={"/Wardrobe"} element={<Wardrobe></Wardrobe>}></Route>
      <Route path={"/ComputerTable"} element={<ComputerTable></ComputerTable>}></Route>
      <Route path={"/BigStudytable"} element={<BigStudytable></BigStudytable>}></Route>
      <Route path={"/Bedside"} element={<Bedside></Bedside>}></Route>
     </Routes> 
     </>
  </Router> 






   

   </>
  );
}

export default App;
