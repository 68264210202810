import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
function Home() {
  return (
  <>
   <Navbar></Navbar>
<br></br>

<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div className="container p-0 mb-5">
  <div class="row no-gutters">
    <div class="col-sm-1"></div>
    <div class="col-sm-5">
        <div class="firdtbgm">
            <div class="container">
                <br></br><br></br> 
                <h2>Lowest Prices Best Quality Shopping</h2><br></br>
                <button class="btn btn-home"> Shop Now</button>
            </div>
        </div>
    </div>
    <div class="col-sm-5">
        <div class="fimg">
        </div>
    </div>
</div>
    </div>

    </div>
    <div class="carousel-item">
    <div className="container p-0 mb-5">
  <div class="row no-gutters">
    <div class="col-sm-1"></div>
    <div class="col-sm-5">
        <div class="firdtbgm">
            <div class="container">
                <br></br><br></br> 
                <h2>Lowest Prices Best Quality Shopping</h2><br></br>
                <button class="btn btn-home"> Shop Now</button>
            </div>
        </div>
    </div>
    <div class="col-sm-5">
        <div class="fimg1">
        </div>
    </div>
</div>
    </div> 

    </div>
    <div class="carousel-item">
    <div className="container p-0 mb-5">
  <div class="row no-gutters">
    <div class="col-sm-1"></div>
    <div class="col-sm-5">
        <div class="firdtbgm">
            <div class="container">
                <br></br><br></br> 
                <h2>Lowest Prices Best Quality Shopping</h2><br></br>
                <button class="btn btn-home"> Shop Now</button>
            </div>
        </div>
    </div>
    <div class="col-sm-5">
        <div class="fimg2">
        </div>
    </div>
</div>
    </div> 


    </div>
    <div class="carousel-item">
    <div className="container p-0 mb-5">
  <div class="row no-gutters">
    <div class="col-sm-1"></div>
    <div class="col-sm-5">
        <div class="firdtbgm">
            <div class="container">
                <br></br><br></br> 
                <h2>Lowest Prices Best Quality Shopping</h2><br></br>
                <button class="btn btn-home"> Shop Now</button>
            </div>
        </div>
    </div>
    <div class="col-sm-5">
        <div class="fimg3">
        </div>
    </div>
</div>
    </div> 


    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>






    
<div class="container">
    <div class="row">
        <div class="col-sm-4">
            <hr></hr>
        </div>
        <div class="col-sm-4"><center><h2>Product</h2></center></div>
        <div class="col-sm-4">
            <hr></hr>
        </div>
    </div>
</div>
<br></br>







<div class="container home2">
    <div class='row  mb-5 wow fadeInUp" data-wow-delay="1.8s" '>
        
        <div class="col-sm-3 wow fadeInUp" data-wow-delay="0.1s">
<div class="hometextposition">
            <h1 class="hometext">New Models </h1>
            <a href='/Allproduct'> <button class='btn btn-sec'>View all</button></a>
         </div>
        </div>
        <div class="col-sm-3 wow fadeInUp" data-wow-delay="0.3s">
    <br></br><br></br>
    <div class="card cardbg" ><br></br>
        <div class="container">
            <div class="imgbgm">
                <img class="img-fluid" src="./styl/pimg/bed1.png" alt=""/>
            </div>
        </div>
        <br></br>
        <div class="container">
            <div class="imgbgm">
                <img class="imasy" src="./styl/pimg/oc1.png" alt=""/>
            </div>
        </div>
        <br></br>
    </div>
    <center><a href='/Allproduct'><buttoo class="btn btn-crdbtn"> Shop Now</buttoo></a></center>
</div>



<div class="col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
    <br></br><br></br>
    <div class="card cardbg" ><br></br>
        <div class="container">
            <div class="imgbgm">
            <img class="imasy" src="./styl/pimg/stand1.png" alt=""/>
            </div>
        </div>
        <br></br>
        <div class="container">
            <div class="imgbgm">
                <img class="imasy" src="./styl/pimg/oc1.png" alt=""/>
            </div>
        </div>
        <br></br>
    </div>
    <center><a href='/Allproduct'><buttoo class="btn btn-crdbtn"> Shop Now</buttoo></a></center>
</div>



<div class="col-sm-3 wow fadeInUp" data-wow-delay="0.9s">
    <br></br><br></br>
    <div class="card cardbg" ><br></br>
        <div class="container">
            <div class="imgbgm">
                <img class="img-fluid" src="./styl/pimg/bed1.png" alt=""/>
            </div>
        </div>
        <br></br>
        <div class="container">
            <div class="imgbgm">
                <img class="imasy" src="./styl/pimg/oc1.png" alt=""/>
            </div>
        </div>
        <br></br>
    </div>
    <center><a href='/Allproduct'><buttoo class="btn btn-crdbtn"> Shop Now</buttoo></a></center>
</div>


    </div>
    
</div>

<br></br><br></br><br></br><br></br>

<div class="container bgh">
       
       <div class="container">
       <div class="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
           <div class="col-lg-6">
               <p class="text-white"><span class="text-white me-2">#</span>Our Services</p>
               <h1 class="display-5 mb-0 text-white">Special Services For <span class="text-white">Symphony</span> Furniture</h1>
           </div>
           <div class="col-lg-6">
               <div class="bg-primary h-100 d-flex align-items-center py-4 px-4 px-sm-5">
                   <i class="fa fa-3x fa-mobile-alt text-white"></i>
                   <div class="ms-4">
                       <p class="text-white mb-0">Call for more info</p>
                       <h2 class="text-white mb-0">+91-9961700988</h2>
                   </div>
               </div>
           </div>
       </div>
       <div class="row gy-5 gx-4">
           <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
           <i class="fa-solid fa-industry fa2"></i>
               <h5 class="mb-3 btext text-white">Manufacturing</h5>
               <span class="text-white ptext">Companies that design and produce furniture, ranging from mass-produced items to custom-made pieces.</span>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
           <i class="fa-solid fa-circle-info fa2"></i>
               <h5 class="mb-3 btext text-white">Retail</h5>
               <span class="text-white ptext">Stores that sell furniture directly to consumers, either online or through physical storefronts.</span>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
           <i class="fa-solid fa-boxes-packing fa2"></i>
               <h5 class="mb-3 btext text-white">Wholesale</h5>
               <span class="text-white ptext">Businesses that supply furniture to retailers, designers, or other businesses in bulk quantities.</span>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
           <i class="fa-solid fa-pen-nib fa2"></i>
               <h5 class="mb-3 text-white btext">Custom Design</h5>
               <span class="text-white ptext">Services that offer bespoke furniture design and fabrication tailored to individual customer preferences.</span>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
           <i class="fa-solid fa-chair fa2"></i>
               <h5 class="mb-3 text-white btext">Restoration and Refinishing</h5>
               <span class="text-white ptext">Companies that specialize in restoring antique or damaged furniture, as well as refinishing pieces to give them a new look.</span>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
           <i class="fa-solid fa-truck fa2"></i>
          
               <h5 class="mb-3 text-white btext">Doorstep Delivery
</h5>
               <span class="text-white ptext">Experience the convenience of doorstep delivery with Symphony Furniture. We partner with trusted delivery agencies to ensure that your furniture is transported directly to your home with care and precision. Whether you reside in the bustling city or tranquil suburbs, we coordinate delivery according to your schedule.</span>
          
             </div>
   
        
       </div>
   </div>
   <br></br> <br></br> <br></br>
</div>












    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <p><span className="text-primary me-2">#</span>Welcome To Symphony Furniture</p>
                    <h1 className="display-5 mb-4">Symphony Furniture<span className="text-primary"> Crafting Elegance</span>  and Comfort for Your Home </h1>
                    <p className="mb-4">At Symphony Furniture, we believe in enriching living spaces with elegance and functionality. Our meticulously crafted furniture is designed to enhance comfort and style in every room of your home. From luxurious chairs to sturdy beds, versatile tables to ergonomic office furniture, each piece is crafted with care and precision to elevate your living experience. With Symphony Furniture, you can trust in superior quality and timeless design that harmonizes with your lifestyle.</p>
                  <br></br>
                  <p className="mb-4">Craftsmanship: Our skilled artisans meticulously handcraft each piece using premium materials, ensuring durability and longevity.
 Comfort: Experience unparalleled comfort with our thoughtfully designed furniture tailored for relaxation and enjoyment.
 Style: From classic designs to modern aesthetics, our furniture reflects a blend of sophistication and innovation.
    Functionality: Whether it's optimizing space with multi-functional pieces or providing ergonomic solutions for your workspace, our furniture is designed with your needs in mind.
                    </p>
                    <a className="btn btn-primary py-3 px-5 mt-3" href="">Read More</a>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="img-border">
                        <img className="img-fluid" src="../styl/img/a1.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/*    
    <!-- Facts Start --> */}
    {/* <div class="container-xxl bg-primary facts my-5 py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-4">
                <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                <i class="fa-solid fa-face-smile text-primary1 fa-3x mb-3"></i>
                    <h1 class="text-white mb-2" data-toggle="counter-up">12345</h1>
                    <p class="text-white mb-0">Happy clients</p>
                </div>
                <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                    <i class="fa fa-users fa-3x text-primary1 mb-3"></i>
                    <h1 class="text-white mb-2" data-toggle="counter-up">12345</h1>
                    <p class="text-white mb-0">Workers</p>
                </div>
                <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                    <i class="fa fa-certificate fa-3x text-primary1 mb-3"></i>
                    <h1 class="text-white mb-2" data-toggle="counter-up">12345</h1>
                    <p class="text-white mb-0">Shiped workers </p>
                </div>
                <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
                    <i class="fa fa-shield-alt fa-3x text-primary1 mb-3"></i>
                    <h1 class="text-white mb-2" data-toggle="counter-up">12345</h1>
                    <p class="text-white mb-0">Staff</p>
                </div>
            </div>
        </div>
    </div>
    <br></br> */}
    {/* <!-- Facts End --> */}

{/* 
    <!-- Service Start --> */}
 
  
    

    {/* <!-- Service End --> */}

    {/* <!-- Animal Start --> */}
    {/* <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                <div class="col-lg-6">
                    <p><span class="text-primary me-2">#</span>Our Animals</p>
                    <h1 class="display-5 mb-0">Let`s See Our <span class="text-primary">Zoofari</span> Awsome Animals</h1>
                </div>
                <div class="col-lg-6 text-lg-end">
                    <a class="btn btn-primary py-3 px-5" href="">Explore More Animals</a>
                </div>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="row g-4">
                        <div class="col-12">
                            <a class="animal-item" href="../styl/img/c1.png" data-lightbox="animal">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../styl/img/c1.png" alt=""/>
                                    <div class="animal-text p-4">
                                        <p class="text-white small text-uppercase mb-0">Animal</p>
                                        <h5 class="text-white mb-0">Elephant</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12">
                            <a class="animal-item" href="img/animal-lg-1.jpg" data-lightbox="animal">
                                <div class="position-relative">
                                    <img class="img-fluid" src="./styl/img/animal-lg-1.jpg" alt=""/>
                                    <div class="animal-text p-4">
                                        <p class="text-white small text-uppercase mb-0">Animal</p>
                                        <h5 class="text-white mb-0">Elephant</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="row g-4">
                        <div class="col-12">
                            <a class="animal-item" href="img/animal-lg-2.jpg" data-lightbox="animal">
                                <div class="position-relative">
                                    <img class="img-fluid" src="./styl/img/animal-lg-2.jpg" alt=""/>
                                    <div class="animal-text p-4">
                                        <p class="text-white small text-uppercase mb-0">Animal</p>
                                        <h5 class="text-white mb-0">Elephant</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12">
                            <a class="animal-item" href="img/animal-md-2.jpg" data-lightbox="animal">
                                <div class="position-relative">
                                    <img class="img-fluid" src="./styl/img/animal-md-2.jpg" alt=""/>
                                    <div class="animal-text p-4">
                                        <p class="text-white small text-uppercase mb-0">Animal</p>
                                        <h5 class="text-white mb-0">Elephant</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="row g-4">
                        <div class="col-12">
                            <a class="animal-item" href="img/animal-md-3.jpg" data-lightbox="animal">
                                <div class="position-relative">
                                    <img class="img-fluid" src="./styl/img/animal-md-3.jpg" alt=""/>
                                    <div class="animal-text p-4">
                                        <p class="text-white small text-uppercase mb-0">Animal</p>
                                        <h5 class="text-white mb-0">Elephant</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12">
                            <a class="animal-item" href="img/animal-lg-3.jpg" data-lightbox="animal">
                                <div class="position-relative">
                                    <img class="img-fluid" src="./styl/img/animal-lg-3.jpg" alt=""/>
                                    <div class="animal-text p-4">
                                        <p class="text-white small text-uppercase mb-0">Animal</p>
                                        <h5 class="text-white mb-0">Elephant</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- Animal End --> */}

    {/* <!-- Membership Start --> */}
    {/* <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                <div class="col-lg-6">
                    <p><span class="text-primary me-2">#</span>Membership</p>
                    <h1 class="display-5 mb-0">You Can Be A Proud Member Of <span class="text-primary">Zoofari</span></h1>
                </div>
                <div class="col-lg-6 text-lg-end">
                    <a class="btn btn-primary py-3 px-5" href="/Bed">Special Offer</a>
                </div>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="membership-item position-relative">
                        <img class="img-fluid" src="./styl/pimg/bed1.png" alt=""/>
                  
                        <a class="btn btn-outline-light px-4 mt-3" href="">Show More</a>
                    </div><br></br>
                    <div class="row">
                        <div class="col-sm-4"> <h6 class="display-8">Bed</h6></div>
                        <div class='col-sm-4'></div>
                        <div class="col-sm-4 text-success"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i> </div>
                    </div>
                    

                </div>
              
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="membership-item position-relative">
                        <img class="img-fluid fg" src="./styl/pimg/oc1.png" alt=""/>
                 
                        <a class="btn btn-outline-light px-4 mt-3" href="/Chair">Show More</a>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-8"> <h6 class="display-8">Office Chairs</h6></div>
                      
                        <div class="col-sm-4 text-success"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i> </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="membership-item position-relative">
                        <img class="img-fluid" src="./styl/pimg/stand1.png" alt=""/>
                        <a class="btn btn-outline-light px-4 mt-3" href="/Stand">Show More</a>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4"> <h6 class="display-8">Stand</h6></div>
                        <div class='col-sm-4'></div>
                        <div class="col-sm-4 text-success"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i> </div>
                    </div>
                </div>
            </div>
<br></br><br></br>
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="membership-item position-relative">
                        <img class="img-fluid" src="./styl/pimg/table1.png" alt=""/>
                        <a class="btn btn-outline-light px-4 mt-3" href="/Table">Show More</a>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4"> <h6 class="display-8">Table</h6></div>
                        <div class='col-sm-4'></div>
                        <div class="col-sm-4 text-success"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i> </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="membership-item position-relative">
                        <img class="img-fluid fg" src="./styl/pimg/m1.png" alt=""/>
                        <a class="btn btn-outline-light px-4 mt-3" href="/Manora">Show More</a>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4"> <h6 class="display-8">Manora</h6></div>
                        <div class='col-sm-4'></div>
                        <div class="col-sm-4 text-success"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i> </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="membership-item position-relative">
                        <img class="img-fluid" src="./styl/pimg/ch2.png" alt=""/>
                        <a class="btn btn-outline-light px-4 mt-3" href="/Chair">Show More</a>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4"><h6 class="display-8">Chair</h6></div>
                        <div class='col-sm-4'></div>
                        <div class="col-sm-4 text-success"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i> </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
  <Footer></Footer>
  </>
  )
}
export default Home
