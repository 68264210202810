import React from 'react'

function Coomingsoon() {
  return (
    <div>
      <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="../styl/img/comming.jpg" alt="First slide"/>
    </div>
 
  </div>
</div>
    </div>
  )
}

export default Coomingsoon
