import React from 'react';
import { NavLink } from 'react-router-dom';
import DATA from '../Product/Data';
import Navbar from '../Navbar';
import Footer from '../Footer';

const constructWhatsAppLink = (phoneNumber, id, img, title, price) => {
    const message = `Details for product with ID ${id}: \nTitle: ${title}\nPrice: ${price}\nImage: ${img}`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
}

const Table = () => {
    const phoneNumber = '+919961700988'; // Replace with the desired WhatsApp number
    const chairsData = DATA.filter(item => item.title === 'Table');
    return (
        <React.Fragment>
            <Navbar />
            <div className="content">
                <div className="container">
                    <div className="content-top" id="product">
                        <br /><br /><br />
                        <center><h1>Table</h1></center>
                        <div className="row">
                            {chairsData.map((item) => (
                                <div className="col-md-3" key={item.id}>
                                    <div className="product-card">
                                        <div className="badge">Sales</div>
                                        <div className="product-tumb">
                                            <NavLink to={`../products/${item.id}`}>
                                                <img className="" src={item.img} alt={item.title} />
                                            </NavLink>
                                        </div>
                                        <div className="product-details">
                                            <h4><a href="#">{item.title}</a></h4>
                                            <div className="product-bottom-details">
                                                <div className="product-price">
                                                    <small>{new Date(item.date).toLocaleDateString()}</small>
                                                    {item.price}
                                                </div>
                                                <div className="product-links">
                                                    <a href={constructWhatsAppLink(phoneNumber, item.id, item.img, item.title, item.price)} target="_blank" rel="noopener noreferrer"><button class="btn btnbuy">Book now</button></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default Table;
