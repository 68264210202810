import React from 'react'

function Footer() {
  return (
    <div>
          {/* <!-- Footer Start --> */}
    <div class="container-fluid footer  text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-3 col-md-6">
                    <h5 class="text-light mb-4">Address</h5>
                 <a href='https://www.google.com/maps/uv?pb=!1s0x3b08071d242ed5fd%3A0x372dc966590dabcc!3m1!7e115!4s%2Fmaps%2Fplace%2Fsymphony%2Bfurniture%2Bkalady%2F%4010.1706572%2C76.4300708%2C3a%2C75y%2C0.32h%2C90t%2Fdata%3D*213m4*211e1*213m2*211sgLlo7urYojz88ZAFeYRkMg*212e0*214m2*213m1*211s0x3b08071d242ed5fd%3A0x372dc966590dabcc%3Fsa%3DX%26ved%3D2ahUKEwiQ55b3z-6FAxUkd2wGHYkVDcMQpx96BAgIEAA!5ssymphony%20furniture%20kalady%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e2!2sgLlo7urYojz88ZAFeYRkMg&hl=en&ved=1t%3A206134&ictx=111'>  <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>MC road, Mattoor junction, Kalady, Angamaly, Kerala 683574, near nedubassery airport road</p></a> 
                 <p class="mb-2"><i class="fa fa-envelope me-3"></i>symphonyfurnit@gmail.com</p>
                 <p class="mb-2"><i class="fa fa-envelope me-3"></i>support@symphonyfurniture.in </p>
                    <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+919961700988</p>
                    <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+919562852558 </p>
                    <div class="d-flex pt-2">
                      
                        <a class="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=61558484047859"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-light btn-social" href="https://youtube.com/@stejeeshpaul?si=L8_o_J9TrKibNAQt"><i class="fab fa-youtube"></i></a>
                 
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h5 class="text-light mb-4">Quick Links</h5>
                    <a class="btn btn-link" href="/About">About Us</a>
                    <a class="btn btn-link" href="/Contact">Contact Us</a>
                    <a class="btn btn-link" href="/Services">Our Services</a>
                    <a class="btn btn-link" href="/Allproduct">Product</a>
               
                </div>
                <div class="col-lg-1 col-md-6">
                  
                </div>
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-light mb-4">Stay in the Loop with Symphony Furniture</h5>
                    <p>Get exclusive updates, offers, and design inspiration delivered to your inbox. Join now for the latest in furniture trends and special promotions.</p>
                    <div class="position-relative mx-auto" style={{maxWidth: '400px'}}>
                        <input class="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center lasttext text-md-start mb-3 mb-md-0">
                    <font color="000">  &copy;</font>   <a class="border-bottom" href="#"><font color="000">Symphony Furniture</font></a><font color="000">, All Right Reserved.</font>
                    </div>
                    <div class="col-md-6 text-center lasttext text-md-end">
                     
                    <font color="000">   Designed By</font>  <a class="border-bottom" href="https://www.infosiltechnolabs.com/"><font color="000">Infosiltechnolabs</font></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Footer End --> */}
    </div>
  )
}

export default Footer
