import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
function Contact() {
  return (
   <>
   <Navbar></Navbar>
   <div class="container-fluid header-bg2 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <h1 class="display-4 text-white mb-3 animated slideInDown">Contact Us</h1>
          
        </div>
    </div>

    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-4 mb-5">
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="h-100 bg-light1 d-flex align-items-center p-5">
                        <div class="btn-lg-square bg-white flex-shrink-0">
                            <i class="fa fa-map-marker-alt text-primary"></i>
                        </div>
                        <div class="ms-4">
                            <p class="mb-2"><span class="text-primary me-2">#</span>Address</p>
                            <h5 class="mb-0">MC road, Mattoor junction, Kalady, Angamaly, Kerala 683574, near nedubassery airport road</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="h-100 bg-light1 d-flex align-items-center p-5">
                        <div class="btn-lg-square bg-white flex-shrink-0">
                            <i class="fa fa-phone-alt text-primary"></i>
                        </div>
                        <div class="ms-4">
                            <p class="mb-2"><span class="text-primary me-2">#</span>Call Now</p>
                            <h5 class="mb-0">9961700988</h5>
                            <h5 class="mb-0">9562852558</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="h-100 bg-light1 d-flex align-items-center p-5">
                        <div class="btn-lg-square bg-white flex-shrink-0">
                            <i class="fa fa-envelope-open text-primary"></i>
                        </div>
                        <div class="ms-4">
                            <p class="mb-2"><span class="text-primary me-2">#</span>Mail Now</p>
                            <h5 class="add mb-0">symphonyfurnit@gmail.com </h5>
                            <h5 class=" add mb-0">support@symphonyfurniture.in </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <p><span class="text-primary me-2">#</span>Contact Us</p>
                    <h1 class="display-5 mb-4"> Contact</h1>
                   
                    <form>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control bg-light1 border-0" id="name" placeholder="Your Name"/>
                                    <label for="name">Your Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="email" class="form-control bg-light1 border-0" id="email" placeholder="Your Email"/>
                                    <label for="email">Your Email</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control bg-light1 border-0" id="subject" placeholder="Subject"/>
                                    <label for="subject">Subject</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <textarea class="form-control bg-light1 border-0" placeholder="Leave a message here" id="message" style={{height: "100px"}}></textarea>
                                    <label for="message">Message</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="" >
                       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3927.1219165844464!2d76.42749637478613!3d10.170744470073915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b08071d242ed5fd%3A0x372dc966590dabcc!2sSymphony%20steel%20furniture!5e0!3m2!1sen!2sin!4v1714735570479!5m2!1sen!2sin"style={{width:"600px", height:"495px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   
   <Footer></Footer>
   </>
  )
}

export default Contact
