import React from "react";

function Navbar() {
  return (
    <div>
      <div className="topbg"></div>
      <div
        className="container-fluid bg-light p-0 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4"></div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>
                <font color="#000">9961700988 , 9562852558</font>
              </small>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                href="https://www.facebook.com/profile.php?id=61558484047859"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                href="https://youtube.com/@stejeeshpaul?si=L8_o_J9TrKibNAQt"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-0"
                href="https://www.instagram.com/stejeeshsymphony/"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav
        className="navbar navbar-expand-lg bgnav sticky-top py-lg-0 px-4 px-lg-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <a href="/" className="">
          <img className="logo" src="../styl/img/icon/logo.png" alt="Icon" />
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse py-4 py-lg-0"
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto">
            <a href="/" className="nav-item nav-link">
              Home
            </a>
            <a href="/About" className="nav-item nav-link">
              About
            </a>
            <a href="/Services" className="nav-item nav-link">
              Services
            </a>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Product
              </a>
              <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                <div className="dropdown-submenu">
                  <a href="#" className="dropdown-item dropdown-toggle">
                    Chair
                  </a>
                  <div className="dropdown-menu rounded-0 m-0">
                  <a href="/Chair" className="dropdown-item">
                      Chair
                    </a>
                    <a href="/Officechair" className="dropdown-item">
                      Office Chair
                    </a>
                    <a href="/GamingChair" className="dropdown-item">
                      Gaming Chair
                    </a>
                    <a href="/DiningChair" className="dropdown-item">
                      Dining Chair
                    </a>
                  </div>
                </div>
                <a href="/Wardrobe" className="dropdown-item ">
                Wardrobe
                  </a>
                <div className="dropdown-submenu">
                  <a href="#" className="dropdown-item dropdown-toggle">
                    Stand
                  </a>
                  <div className="dropdown-menu rounded-0 m-0">
                    <a href="/ClothStand" className="dropdown-item">
                    cloth stand
                    </a>
                    <a href="/Stand" className="dropdown-item">
                    cloth dryer stand
                    </a>
                    <a href="#" className="dropdown-item">
                      ....
                    </a>
                  </div>
                </div>
                <a href="/Mattress" className="dropdown-item">
                Mattress
                </a>
                {/* <a href="/Bed" className="dropdown-item">
                  Bed
                </a> */}
                {/* <a href="/Bedsheet" className="dropdown-item">
                  Bedsheet
                </a> */}

<div className="dropdown-submenu">
                  <a href="#" className="dropdown-item dropdown-toggle">
                  Bed
                  </a>
                  <div className="dropdown-menu rounded-0 m-0">
                    <a href="/Bed" className="dropdown-item">
                    Bed
                    </a>
                    <a href="/Bedside" className="dropdown-item">
                    Bed Side
                    </a>
                  
                  </div>
                </div>











                <div className="dropdown-submenu">
                  <a href="#" className="dropdown-item dropdown-toggle">
                  Bedsheet
                  </a>
                  <div className="dropdown-menu rounded-0 m-0">
                    <a href="/Bedsheet" className="dropdown-item">
                    Bedsheet
                    </a>
                    <a href="/FamilycotBedsheet" className="dropdown-item">
                    Familycot Bedsheet
                    </a>
                    <a href="/PillowJodi" className="dropdown-item">
                    Pillow Jodi
                    </a>
                    <a href="/BoxtypeBedsheet" className="dropdown-item">
                    Boxtype Bedsheet
                    </a>
                    <a href="/KingBedsheet" className="dropdown-item">
                    King Bedsheet
                    </a>
                  </div>
                </div>
















                
                <div className="dropdown-submenu">
                  <a href="#" className="dropdown-item dropdown-toggle">
                    Table
                  </a>
                  <div className="dropdown-menu rounded-0 m-0">
                    <a href="/Table" className="dropdown-item">
                      Table
                    </a>
                    {/* <a href="/Dtable" className="dropdown-item">
                      Dining Table
                    </a> */}
                    <a href="/ComputerTable" className="dropdown-item">
                      Computer Table
                    </a>
                    <a href="/StudyTable" className="dropdown-item">
                      Study Table
                    </a>
                    <a href="/BigStudytable" className="dropdown-item">
                      Big Study Table
                    </a>
                  </div>
                </div>
                {/* <a href="/Dtable" className="dropdown-item">
                  Dining Table
                </a> */}
                <a href="/Cradle" className="dropdown-item">
                  Cradle
                </a>
                
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Special Ttem
              </a>
              <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                <a href="/Podium" className="dropdown-item">
                  Podium
                </a>
                <a href="/Manora" className="dropdown-item">
                  Manora
                </a>
                {/* <a href="/Bed" className="dropdown-item">Bed</a>
                <a href="/Bedsheet" className="dropdown-item">Bedsheet</a>
                <a href="/Table" className="dropdown-item">Table</a>
                <a href="/Dtable" className="dropdown-item">Dining Table</a>
                <a href="/Cradle" className="dropdown-item">Cradle</a>
                <a href="/Manora" className="dropdown-item">Manora</a> */}
              </div>
            </div>
            <a href="/Contact" className="nav-item nav-link">
              Contact
            </a>
        
          </div>
          <a href="/Allproduct" className="btn btn-primary">
            Buy Now<i className="fa fa-arrow-right ms-3"></i>
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
