import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import DATA from '../Product/Data';
import { useDispatch } from 'react-redux';
import { addItem, delItem } from '../redux/actions/index';
import Navbar from '../Navbar';
import Footer from '../Footer';

const ProductDetail = () => {
    const [cartBtn, setCartBtn] = useState("Add to Cart");
    const [count, setCount] = useState(1); // Initialize count to 1
    const proid = useParams();
    const proDetail = DATA.filter(x => x.id == proid.id);
    const product = proDetail[0];

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const constructWhatsAppLink = (phoneNumber, id, desc, title, price, count) => {
        const message = `Details for product with ID ${id}: \nTitle: ${title}\nPrice: ${price}\nDesc: ${desc}\nCount: ${count}`;
        const encodedMessage = encodeURIComponent(message);
        return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    };

    const handleCart = (product) => {
        if (cartBtn === "Add to Cart") {
            dispatch(addItem(product));
            setCartBtn("Add to Cart");
            const whatsappLink = constructWhatsAppLink('+919961700988', product.id, product.desc, product.title, product.price, count);
            window.open(whatsappLink, '_blank');
        } else {
            dispatch(delItem(product));
            setCartBtn("Add to Cart");
        }
    };

    const incrementCount = () => {
        setCount(count + 1);
    };

    const decrementCount = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    return (
        <>
            <Navbar />
          
            <div className="container toping1">
                <div className="row">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">

                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <img src={product.img} alt={product.title} className='img-fluid pimg' />
    </div>
    <div class="carousel-item">
    <img src={product.img1} alt={product.title} className='img-fluid pimg' />
    </div>
    <div class="carousel-item">
    <img src={product.img2} alt={product.title} className='img-fluid pimg' />
    </div>
    <div class="carousel-item">
    <img src={product.img3} alt={product.title} className='img-fluid pimg' />
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>




                        {/* <img src={product.img} alt={product.title} className='img-fluid pimg' /> */}
                    </div>
                    
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s" d-flex flex-column justify-content-center>
                    <br></br>  <br></br>  <br></br><br></br>  <br></br> 
                        <h1 className="display-5 fw-bold">{product.name}</h1>
                        <hr />
                        <h2 className="my-4">₹{product.price}</h2>
                        <p className="lead">{product.desc}</p>
                        <p class="specification">{product.specification}</p>
                        <p class="warranty"><i class="fa-solid fa-award"></i> {product.warranty}</p>
                        <div className="d-flex align-items-center">
                            <button className="btn btn-secondary me-2" onClick={decrementCount}>-</button>
                            <input type="number" value={count} className="form-control me-2" readOnly />
                            <button className="btn btn-secondary me-2" onClick={incrementCount}>+</button>
                            <button onClick={() => handleCart(product)} className="btn btn-success">{cartBtn}</button>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <Footer />
        </>
    );
}

export default ProductDetail;
