import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
function Services() {
  return (
   <>
   <Navbar></Navbar>
    <div class="container-fluid header-bg py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <h1 class="display-4 text-white mb-3 animated slideInDown"><b>Services</b></h1>
        </div>
    </div>
   
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="col-lg-6">
                    <p class="text-white"><span class=" me-2">#</span>Our Services</p>
                    <h1 class="display-5 mb-0 ">Special Services For <span class="text-dark">Symphony</span> Furniture</h1>
                </div>
                <div class="col-lg-6">
                    <div class="bg-primary h-100 d-flex align-items-center py-4 px-4 px-sm-5">
                        <i class="fa fa-3x fa-mobile-alt "></i>
                        <div class="ms-4">
                            <p class="text-white mb-0">Call for more info</p>
                            <h2 class="text-white mb-0">+91-9961700988</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-5 gx-4">
                <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <i class="fa-solid fa-industry fa2"></i>
                    <h5 class="mb-3 ">Manufacturing</h5>
                    <span class="">Companies that design and produce furniture, ranging from mass-produced items to custom-made pieces.</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <i class="fa-solid fa-circle-info fa2"></i>
                    <h5 class="mb-3 ">Retail</h5>
                    <span class="">Stores that sell furniture directly to consumers, either online or through physical storefronts.</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <i class="fa-solid fa-boxes-packing fa2"></i>
                    <h5 class="mb-3 ">Wholesale</h5>
                    <span class="">Businesses that supply furniture to retailers, designers, or other businesses in bulk quantities.</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                <i class="fa-solid fa-pen-nib fa2"></i>
                    <h5 class="mb-3 ">Custom Design</h5>
                    <span class="">Services that offer bespoke furniture design and fabrication tailored to individual customer preferences.</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <i class="fa-solid fa-chair fa2"></i>
                    <h5 class="mb-3 ">Restoration and Refinishing</h5>
                    <span class="">Companies that specialize in restoring antique or damaged furniture, as well as refinishing pieces to give them a new look.</span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">

            <i class="fa-solid fa-truck fa2"></i>
                    <h5 class="mb-3 ">Doorstep Delivery</h5>
                    <span class="">Experience the convenience of doorstep delivery with Symphony Furniture. We partner with trusted delivery agencies to ensure that your furniture is transported directly to your home with care and precision. Whether you reside in the bustling city or tranquil suburbs, we coordinate delivery according to your schedule.</span>
                
                 </div>
               
            </div>
        </div>
    </div>
   
   
   
   
   
   <Footer></Footer>
   </>
  )
}

export default Services
